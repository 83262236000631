import React, { useContext } from "react"
import styled from "styled-components"
import { myContext } from "../ThemeContext"

export default function PageTitle({ title, subtitle, description, ...props }) {
  const theme = useContext(myContext)
  return (
    <Title theme={theme}>
      <div
        className={`section-title with-desc ${
          props.leftAlign ? "" : "text-center"
        }`}
      >
        <div className="title-header">
          {subtitle ? <span className="title">{subtitle}</span> : ""}
          {title ? (
            <h2 className="title">{title}</h2>
          ) : (
            <h2>no title prop passed</h2>
          )}
        </div>
        <p>{description ? description : ""}</p>
      </div>
    </Title>
  )
}

const Title = styled.section`
  margin: 2rem 0 4rem 0;
  .section-title {
    position: relative;
    margin: 2rem 0 4rem 0;
    &.with-desc.text-center .title-header:before {
      left: 50%;
      margin-left: -26px;
    }
    &.with-desc .title-header {
      margin-bottom: 52px;
    }
    span {
      font-weight: 500;
      text-transform: capitalize;
      font-size: 16px;
      line-height: 23px;
      margin-bottom: 5px;
      color: ${props => props.theme.secondaryBlue};
    }
    h2.title {
      font-size: 34px;
      line-height: 44px;
      margin-bottom: 0;
      color: ${props => props.theme.primaryBlue};
    }
    p {
      text-align: center;
    }
    .title-header {
      margin-bottom: 40px;
      position: relative;
      &:before {
        width: 52px;
        bottom: -20px;
        height: 4px;
        left: 0;
        top: auto;
        position: absolute;
        content: "";
        border: 4px solid transparent;
        border-bottom: 0;
        border-top-color: ${props => props.theme.primaryBlue};
      }
    }
  }
`
