import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Container, Col, Row } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faPlus,
  faChalkboardTeacher,
  faSmile,
  faCompactDisc,
  faChartLine,
  faCheck,
  faSyncAlt,
  faCalendarAlt,
  faTools,
  faUserShield,
} from "@fortawesome/free-solid-svg-icons"
import Accordions from "../Accordion"
import SectionTitle from "../../components/SectionTitle"
import FeatureImageWithDescription from "../FeatureImageWithDescription"

export default function BenefitsOfComputingLeasing() {
  const image = useStaticQuery(graphql`
    query technologyRefresh {
      file(relativePath: { eq: "education/AdobeStock_215722491.jpeg" }) {
        childImageSharp {
          fluid {
            aspectRatio
            base64
            sizes
            src
            srcSet
          }
        }
      }
    }
  `)

  const benefits = [
    {
      title: "Modern equipment with no large capital outlay",
      desc:
        "Compulease™ enables you to install all the very latest equipment you need, without incurring a high capital outlay, leaving money available for use within the school. It also prevents having to delay the implementation of your IT plans until sufficient funds become available.",
      icon: <FontAwesomeIcon icon={faChalkboardTeacher} />,
    },
    {
      title: "Equipment Obsolescence Prevention",
      desc:
        "Your Compulease™ rental will provide you with opportunities to add or change your equipment during the course of the agreement, which may not require any additional budgetary outlay. This provides you with a guaranteed protection against equipment obsolescence which would not be possible if you had purchased the same equipment outright.",
      icon: <FontAwesomeIcon icon={faUserShield} />,
    },
    {
      title: "Adding extra equipment",
      desc:
        "At any time during the rental period you can add extra equipment to your existing agreement.",
      icon: <FontAwesomeIcon icon={faPlus} />,
    },
    {
      title: "Tailored repayments",
      desc:
        "Agreements can be over a period of your choice, between one to five years, and repayments can be made monthly, quarterly, annually or “termly” which ever is most convenient for you.",
      icon: <FontAwesomeIcon icon={faSmile} />,
    },
    {
      title: "Deferred payments",
      desc:
        "You can start getting the benefits of using the new equipment right away and not have to pay for it until your budget becomes available.",
      icon: <FontAwesomeIcon icon={faCalendarAlt} />,
    },
    {
      title: "More than just hardware",
      desc:
        "Your Compulease™ agreement can include hardware, software, installation and even training.",
      icon: <FontAwesomeIcon icon={faTools} />,
    },
    {
      title: "Software leasing",
      desc:
        "Compulease™ can also provide a facility for “software only” so you can take advantage of all the benefits even if there’s no hardware involved.",
      icon: <FontAwesomeIcon icon={faCompactDisc} />,
    },
    {
      title: "Hedge against inflation",
      desc:
        "Computer leasing payments are fixed for the full period of the lease. This enables accurate forward budgeting and the luxury of paying for today’s equipment with tomorrow’s devalued money.",
      icon: <FontAwesomeIcon icon={faChartLine} />,
    },
    {
      title: "Total Flexibility",
      desc:
        "The IT requirements within any teaching environment are constantly changing and future demands are hard to predict. Increasing constraints on budgets also mean that financial resources need to be stretched even further. As a result, schools, colleges and universities have to find new ways to get the most out of their education finance budgets. The Compulease™ program provides a flexible and alternative approach to financing your entire IT requirement, enabling you to maintain, upgrade and build on your existing resources.",
      icon: <FontAwesomeIcon icon={faCheck} />,
    },
    {
      title: "Technology Refresh",
      desc:
        "Your Compulease™ agreement is totally flexible and can be tailored to suit your specific budget profile. At any time during your agreement, you may have more equipment without actually increasing your repayments.",
      icon: <FontAwesomeIcon icon={faSyncAlt} />,
    },
  ]

  return (
    <Container>
      <Row>
        <Col>
          <SectionTitle
            subtitle="Keeping your school in touch with the latest technology as it
                  develops"
            title="Benefits of Leasing for Schools and Colleges"
          />
        </Col>
      </Row>
      <Accordions items={benefits} />
      <FeatureImageWithDescription
        subtitle="Why Compulease"
        title="How does Technology Refresh work?"
        listDescription={[
          "Technology Refresh looks at the number of payments you have made under your current agreement and provides an “available spend figure” where you can acquire further equipment whilst maintaining the same rental figure by refreshing your current contract over an extended term",
          "The extended term is normally equal to the original term, less the balance of the period outstanding for the current agreement. However, this is also flexible in the event you require more equipment than your current refresh figure allows",
        ]}
        image={image.file.childImageSharp.fluid}
      />
    </Container>
  )
}
