import React from "react"
import BenefitsOfComputingLeasing from "../../components/education/BenefitsOfComputerLeasing"
import Layout from "../../components/Layout"
import EducationOffers from "../../components/education/EducationOffers"
import SEO from "../../components/seo"

export default function BenefitsOfLeasing() {
  return (
    <Layout headerType="education">
      <SEO title="Compulease · Benefits of leasing Education" />
      <EducationOffers />
      <BenefitsOfComputingLeasing />
    </Layout>
  )
}
